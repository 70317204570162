<template lang="pug">
Container
	h1 {{ header }}

	div(class='Contacts')
		div(class='ContactsSwitcher')
			div(class='ContactsSwitcher--Switcher', :class='{ FullContainerWidth: lessThan("desktop") }')
				UiButton(
					v-for='{ label, value } in contactsSelectList',
					:key='value',
					:variant='contactsCurrentId === value ? "primary" : "secondary"',
					:size='greaterThan("mobile") ? "md" : "sm"',
					rounded,
					@click='selectActiveContact(value)'
				) {{ label }}

			div(class='ContactsSwitcher--Map')
				iframe(
					v-if='contacts.map',
					:src='contacts.map',
					width='100%',
					height='510',
					frameborder='0'
				)

			div(class='ContactsSwitcher--Content')
				div(class='Address') {{ contacts.name }}

				div(class='Item', v-if='contacts.subway')
					div(class='Title') 
						UiIcon(name='system/metro', size='md', class='Icon')
						span {{ contacts.subway }}
					div(class='Text') {{ contacts.address }}

				div(class='Item', v-if='contacts.workTime')
					div(class='Title') 
						UiIcon(name='system/time', size='md', class='Icon')
						span(v-html='contacts.workTime')

				div(class='Item', v-if='contacts.phone')
					NuxtLink(:href='$f.tel(contacts.phone)', tagret='_blunk', class='Title')
						UiIcon(name='system/phone', size='md', class='Icon')
						span {{ contacts.phone }}

				div(class='Item', v-if='contacts.email')
					NuxtLink(:href='`mailto:${contacts.email}`', tagret='_blunk', class='Title')
						UiIcon(name='system/email', size='md', class='Icon')
						span {{ contacts.email }}

				div(class='Socials')
					div(v-if='variablesList.socialButtonsTitle', class='Title') {{ variablesList.socialButtonsTitle }}:
					div(class='SocialButtons')
						template(v-for='social in socialsState', :key='social.id')
							NuxtLink(
								v-if='social.link && social.icon',
								class='Social',
								:href='social.link',
								:title='social.title',
								target='_blank'
							)
								UiIcon(:name='social.icon', size='lg')

				div(class='Buttons')
					UiButton(
						v-if='contacts.phone',
						:href='$f.tel(contacts.phone)',
						variant='primary',
						theme='filled',
						:size='greaterThan("mobileMedium") ? "xl" : "md"',
						rounded
					) {{ contacts.phone }}

					UiButton(
						v-if='contacts.whatsapp',
						:href='contacts.whatsapp',
						target='_blank',
						variant='whatsapp',
						theme='filled',
						:size='greaterThan("mobileMedium") ? "xl" : "md"',
						icon-left='social/whatsapp-inline',
						:icon-left-size='greaterThan("mobileMedium") ? "md" : "sm"',
						rounded
					) {{ contacts.whatsappText }}

					UiButton(
						v-if='contacts.telegram',
						:href='contacts.telegram',
						target='_blank',
						variant='telegram',
						theme='filled',
						:size='greaterThan("mobileMedium") ? "xl" : "md"',
						icon-left='social/telegram',
						:icon-left-size='greaterThan("mobileMedium") ? "md" : "sm"',
						rounded
					) {{ contacts.telegramText }}

Container
	UnitConsultation(type='employees')
</template>

<script lang="ts" setup>
defineComponent({
	name: 'ContactsPage',
});

/**
 * Define hooks
 */
const { header } = useMeta();
const { lessThan, greaterThan } = usePlatform();
const { socialsState, fetchSocials } = useSocials();
const { variablesList, fetchVariables } = useVariables();
const { contacts, contactsCurrentId, contactsSelectList, fetchContacts } = useContacts();

/**
 * Select active contact
 */
const selectActiveContact = (id: number) => {
	contactsCurrentId.value = id;
};

/**
 * Define fetch
 */
await fetchSocials();
await fetchContacts();
await fetchVariables();
</script>

<style lang="less">
.ContactsSwitcher {
	padding: 2rem 0 0 0;
	.box(grid; 100%; auto; none; 2rem; auto; 1fr; stretch; start);

	&--Switcher {
		.grid(1 2, 1 2);
		.box(flex; 100%; auto; none; center; center; center; nowrap row);
		& > * {
			margin-right: 0.5rem;
			.flex(0; 0; auto);
			&:first-child {
				margin-left: 1rem;
			}
			&:last-child {
				margin-right: 1rem;
			}
		}
	}

	&--Map {
		.grid(3 4, 1 2);
		.relative(1; hidden);
		.box(block; 100%; auto; none);
		.border-radius(@BorderRadiusMedium);
	}
	&--Content {
		padding: 1.5rem;
		.grid(2 3, 1 2);
		.box(grid; 100%; auto; @ColorWhite; 1rem; auto; auto; start; start; row);
		.border-radius(@BorderRadiusMedium);

		& > .Address {
			.grid(1 span);
			.text(@ColorBase; 1.25em 1.5rem @bold; left; none none);
		}
		& > .Item {
			.grid(1 span);
			.box(grid; auto; auto; none; 0.5rem; auto; auto; start; start);
			& > .Title {
				.grid(1 span);
				.text(@ColorBase; 1.25em 1rem @semibold; left; none none);
				.box(grid; 100%; auto; none; 0.5rem; auto; auto 1fr; center; start);

				& > .Icon {
					.grid(1 span);
				}
				& > span {
					.grid(1 span);
					& > * {
						margin: 0;
					}
				}
			}
			& > a.Title {
				&:hover {
					.text(@ColorPrimary);
				}
			}
			& > .Text {
				.grid(1 span);
				.text(@ColorBase; 1.25em 1rem @regular; left; none none);
			}
		}
		& > .Socials {
			.grid(1 span; 1 span);
			.box(grid; 100%; 100%; none; 0.25rem; auto 1fr; 1fr; stretch; start);
			& > .Title {
				.text(@ColorBase; 1.25rem 1rem @semibold);
			}
			& > .SocialButtons {
				.text(@ColorBase; 1.125rem 1.125rem @medium);
				.box(grid; auto; auto; none; 1rem; 1fr; auto; center; start; column);
				& > .Social {
					.text(@ColorBase);
					.border-radius(@BorderRadiusFull);
					.box(grid; 1.5rem; 1.5rem; none; 0; 1fr; 1fr; center; center);
					.transition(all; 250ms; 'sine');
					&:hover {
						.text(@ColorPrimary);
					}
				}
			}
		}
		& > .Buttons {
			margin: 1.5rem 0 0 0;
			.grid(1 span);
			.box(grid; auto; auto; none; 1rem; auto; auto; stretch; start; row);
		}
	}
	@media all and (min-width: @tablet-small) and (max-width: (@laptop - 1px)) {
		&--Switcher {
			.grid(1 2, 1 2);
		}
		&--Map {
			.grid(3 4, 1 2);
		}
		&--Content {
			.grid(2 3, 1 2);
			& > .Buttons {
				.box(grid; auto; auto; none; 1rem; auto; auto; stretch; start; column);
			}
		}
	}

	@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
		.box(grid; 100%; auto; none; 2rem; auto; 1.8fr 1fr; stretch; start);
		&--Switcher {
			.grid(1 2, 1 3);
		}
		&--Map {
			.grid(2 3, 1 2);
		}
		&--Content {
			.grid(2 3, 2 3);
		}
	}

	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; none; 2rem; auto; 1.25fr 1fr; stretch; start);
		&--Switcher {
			.grid(1 2, 1 3);
		}
		&--Map {
			.grid(2 3, 1 2);
		}
		&--Content {
			.grid(2 3, 2 3);
			& > .Buttons {
				.box(grid; auto; auto; none; 1rem; auto; auto; stretch; start; row);
			}
		}
	}
}
</style>
